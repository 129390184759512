import { Theme, useTheme } from '@mui/material';

function AttachFileOffIcon({
  color,
}: {
  color: string | ((theme: Theme) => string);
}) {
  const theme = useTheme();
  const fillColor = typeof color === 'function' ? color(theme) : color;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3788_35203"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill={fillColor} />
      </mask>
      <g mask="url(#mask0_3788_35203)">
        <path
          d="M11.9321 22C10.21 22 8.74329 21.4 7.53213 20.2C6.32113 19 5.71563 17.5389 5.71563 15.8168V8.15L1.51562 3.95L2.69888 2.76675L21.3489 21.4168L20.1656 22.6L16.9739 19.4083C16.4017 20.1916 15.6781 20.8194 14.8031 21.2917C13.9281 21.7639 12.9711 22 11.9321 22ZM7.38213 9.81675V15.8168C7.37663 17.0778 7.81829 18.1458 8.70713 19.0208C9.59613 19.8958 10.6711 20.3333 11.9321 20.3333C12.7488 20.3333 13.4919 20.1416 14.1614 19.7583C14.8309 19.3749 15.3684 18.8583 15.7739 18.2083L14.1906 16.625C13.9628 17.0138 13.6503 17.3291 13.2531 17.5707C12.8558 17.8124 12.4155 17.9333 11.9321 17.9333C11.1988 17.9333 10.5766 17.6805 10.0656 17.175C9.55446 16.6695 9.29888 16.0501 9.29888 15.3168V11.7333L7.38213 9.81675ZM10.9656 13.4V15.3168C10.9656 15.5778 11.0586 15.8013 11.2446 15.9875C11.4308 16.1737 11.66 16.2668 11.9321 16.2668C12.1988 16.2668 12.4225 16.1751 12.6031 15.9918C12.7836 15.8084 12.8766 15.5889 12.8821 15.3333V15.3168L10.9656 13.4ZM16.4656 14.1668V6.06675H18.1321V15.8333L16.4656 14.1668ZM12.8821 10.5833V6.4C12.8766 5.63333 12.6086 4.98608 12.0781 4.45825C11.5475 3.93058 10.8988 3.66675 10.1321 3.66675C9.56546 3.66675 9.05713 3.82225 8.60713 4.13325C8.15713 4.44442 7.83213 4.84442 7.63213 5.33325L6.39063 4.09175C6.78496 3.45275 7.30713 2.94442 7.95713 2.56675C8.60713 2.18892 9.33213 2 10.1321 2C11.3488 2 12.3891 2.42917 13.2531 3.2875C14.117 4.14583 14.5489 5.18333 14.5489 6.4V12.25L12.8821 10.5833ZM10.9656 6.06675V8.66675L9.29888 7V6.06675H10.9656Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}

export default AttachFileOffIcon;

import { Theme, useTheme } from '@mui/material';

function AudioAttachmentIcon({
  color,
}: {
  color: string | ((theme: Theme) => string);
}) {
  const theme = useTheme();
  const fillColor = typeof color === 'function' ? color(theme) : color;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1230_13394"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill={fillColor} />
      </mask>
      <g mask="url(#mask0_1230_13394)">
        <path
          d="M10.7 19.1333C11.3667 19.1333 11.9333 18.9038 12.4 18.4448C12.8667 17.9858 13.1 17.4208 13.1 16.75V12.1333H16.0332V10.4668H12.1V14.875C11.9167 14.7305 11.7048 14.6208 11.4645 14.5458C11.224 14.4708 10.9692 14.4333 10.7 14.4333C10.0527 14.4333 9.5075 14.6583 9.0645 15.1083C8.6215 15.5583 8.4 16.1083 8.4 16.7583C8.4 17.4083 8.6215 17.9666 9.0645 18.4333C9.5075 18.8999 10.0527 19.1333 10.7 19.1333ZM5.66675 22C5.21675 22 4.82642 21.8348 4.49575 21.5043C4.16525 21.1736 4 20.7833 4 20.3333V3.66675C4 3.21675 4.16525 2.82642 4.49575 2.49575C4.82642 2.16525 5.21675 2 5.66675 2H14.35L20 7.65V20.3333C20 20.7833 19.8347 21.1736 19.5042 21.5043C19.1736 21.8348 18.7832 22 18.3333 22H5.66675ZM13.5167 8.43325V3.66675H5.66675V20.3333H18.3333V8.43325H13.5167Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}

export default AudioAttachmentIcon;

'use client';

import { Box, Chip, Typography } from '@mui/material';
import { Button, useIsMobile } from '@chat-dit/shared-ui-design-system';
import React, { useEffect } from 'react';
import { loggerService } from '@chat-dit/shared-utils/client';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const isMobile = useIsMobile();
  useEffect(() => {
    loggerService.getLogger().fatal(error);
  }, [error]);
  return (
    <html>
      <body>
        <Box
          mt={4}
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography align="center" variant="h4">
            {isMobile ? 'AI' : 'Artificial Intelligence'} Hub
          </Typography>
          <Chip label="PREVIEW" sx={{ marginLeft: 2 }} color="primary" />
        </Box>
        <Typography align="center">
          Sorry, we're having some technical difficulties.
        </Typography>
        <Typography align="center" mb={2}>
          Please try again later or contact support if the problem persists.
        </Typography>
        <Box textAlign="center">
          <Button onClick={() => reset()}>Try again</Button>
        </Box>
      </body>
    </html>
  );
}

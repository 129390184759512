'use client';

import { useEffect, useState } from 'react';
import { app, authentication } from '@microsoft/teams-js';
import { getCsrfToken } from 'next-auth/react';
import { Box, Typography } from '@mui/material';

export function TeamsSignIn() {
  const [csrfToken, setCsrfToken] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [form, setForm] = useState<HTMLFormElement | null>(null);

  useEffect(() => {
    getCsrfToken().then((token) => setCsrfToken(token ?? null));
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      app
        .initialize()
        .then(() => {
          return authentication.getAuthToken();
        })
        .then((token: string) => {
          setToken(token);
        })
        .catch((e: unknown) => {
          // if this error has a message that contains "No Parent window found."
          if (
            e &&
            typeof e === 'object' &&
            'message' in e &&
            typeof e.message === 'string' &&
            e.message.includes('No Parent window found.')
          ) {
            // Can be safely ignored, this just means we're running outside of Teams
          } else {
            console.error(e);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (!token) {
      return;
    }
    if (!form) {
      return;
    }

    form.submit();
  }, [form, token]);

  if (typeof window === 'undefined') {
    return null;
  }

  if (!csrfToken) {
    return null;
  }

  return (
    <>
      <form
        ref={(node) => setForm(node)}
        method="post"
        action="/api/auth/callback/credentials"
        id="teamsForm"
      >
        <input name="signInType" type="hidden" defaultValue={'teams'} />
        <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
        <input
          name="access_token"
          type="hidden"
          value={token ?? ''}
          onChange={() => {
            // noop
          }}
        />
        <button type="submit" style={{ display: 'none' }}>
          Sign in
        </button>
      </form>
      <Box textAlign="center">
        <Typography>Automatically signing you in via Teams...</Typography>
      </Box>
    </>
  );
}

import { Slider as MuiSlider, SliderProps, useTheme } from '@mui/material';

function Slider(props: SliderProps) {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const withProvidedSx: SliderProps['sx'] = props.sx ?? {};
  return (
    <MuiSlider
      {...props}
      sx={{
        '& .MuiSlider-track': {
          backgroundColor: 'aiHub.blue.400',
        },
        '& .MuiSlider-rail': {
          backgroundColor:
            mode === 'dark' ? 'aiHub.grey.600' : 'aiHub.blue.300',
          opacity: 1,
        },
        '& .MuiSlider-thumb': {
          backgroundColor: 'aiHub.blue.400',
        },
        '&.Mui-disabled': {
          '& .MuiSlider-track': {
            backgroundColor: 'aiHub.grey.500',
            opacity: 0.3,
          },
          '& .MuiSlider-rail': {
            backgroundColor: 'aiHub.grey.500',
            opacity: 0.3,
          },
          '& .MuiSlider-thumb': {
            backgroundColor: 'aiHub.grey.500',
          },
        },
        ...withProvidedSx,
      }}
    />
  );
}
export default Slider;

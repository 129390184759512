'use client';

import { themeService } from '@chat-dit/shared-utils/client';
import { ThemeProvider } from '@mui/material';

const theme = themeService.createTheme(false);

export function Theme({ children }: { children: React.ReactNode }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

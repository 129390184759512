import { Theme, useTheme } from '@mui/material';

function MediaIcon({ color }: { color: string | ((theme: Theme) => string) }) {
  const theme = useTheme();
  const fillColor = typeof color === 'function' ? color(theme) : color;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 12.3833H16.6L13.3333 8.03325L10.85 11.2833L9.2 9.16675L6.75 12.3833ZM5 16.6668C4.55 16.6668 4.15975 16.5014 3.82925 16.1708C3.49858 15.8403 3.33325 15.45 3.33325 15V1.66675C3.33325 1.21675 3.49858 0.826417 3.82925 0.49575C4.15975 0.16525 4.55 0 5 0H18.3333C18.7833 0 19.1736 0.16525 19.5043 0.49575C19.8348 0.826417 20 1.21675 20 1.66675V15C20 15.45 19.8348 15.8403 19.5043 16.1708C19.1736 16.5014 18.7833 16.6668 18.3333 16.6668H5ZM5 15H18.3333V1.66675H5V15ZM1.66675 20C1.21675 20 0.826417 19.8348 0.49575 19.5043C0.16525 19.1736 0 18.7833 0 18.3333V3.33325H1.66675V18.3333H16.6668V20H1.66675Z"
        fill={fillColor}
      />
    </svg>
  );
}

export default MediaIcon;

function AdvancedIcon({ color }: { color: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_3603_23744"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3603_23744)">
        <path
          d="M17.75 20.25C16.7 20.25 15.8125 19.8875 15.0875 19.1625C14.3625 18.4375 14 17.55 14 16.5C14 15.45 14.3625 14.5625 15.0875 13.8375C15.8125 13.1125 16.7 12.75 17.75 12.75C18.8 12.75 19.6875 13.1125 20.4125 13.8375C21.1375 14.5625 21.5 15.45 21.5 16.5C21.5 17.55 21.1375 18.4375 20.4125 19.1625C19.6875 19.8875 18.8 20.25 17.75 20.25ZM17.75 18.25C18.2333 18.25 18.6458 18.0792 18.9875 17.7375C19.3292 17.3958 19.5 16.9833 19.5 16.5C19.5 16.0167 19.3292 15.6042 18.9875 15.2625C18.6458 14.9208 18.2333 14.75 17.75 14.75C17.2667 14.75 16.8542 14.9208 16.5125 15.2625C16.1708 15.6042 16 16.0167 16 16.5C16 16.9833 16.1708 17.3958 16.5125 17.7375C16.8542 18.0792 17.2667 18.25 17.75 18.25ZM4 17.5V15.5H12V17.5H4ZM6.25 11.25C5.2 11.25 4.3125 10.8875 3.5875 10.1625C2.8625 9.4375 2.5 8.55 2.5 7.5C2.5 6.45 2.8625 5.5625 3.5875 4.8375C4.3125 4.1125 5.2 3.75 6.25 3.75C7.3 3.75 8.1875 4.1125 8.9125 4.8375C9.6375 5.5625 10 6.45 10 7.5C10 8.55 9.6375 9.4375 8.9125 10.1625C8.1875 10.8875 7.3 11.25 6.25 11.25ZM6.25 9.25C6.73333 9.25 7.14583 9.07917 7.4875 8.7375C7.82917 8.39583 8 7.98333 8 7.5C8 7.01667 7.82917 6.60417 7.4875 6.2625C7.14583 5.92083 6.73333 5.75 6.25 5.75C5.76667 5.75 5.35417 5.92083 5.0125 6.2625C4.67083 6.60417 4.5 7.01667 4.5 7.5C4.5 7.98333 4.67083 8.39583 5.0125 8.7375C5.35417 9.07917 5.76667 9.25 6.25 9.25ZM12 8.5V6.5H20V8.5H12Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default AdvancedIcon;

'use client';
import { Button, ButtonProps, useTheme } from '@mui/material';

const sx = {
  dark: {
    primary: {
      textTransform: 'none',
      color: 'white',
      backgroundColor: 'aiHub.blue.600',
      borderRadius: '32px',
      '&.MuiButton-containedPrimary:hover': {
        backgroundColor: 'aiHub.blue.500',
      },
    },
    secondary: {
      textTransform: 'none',
      backgroundColor: 'aiHub.grey.700',
      borderRadius: '32px',
      color: 'white',
      '&.MuiButton-containedSecondary:hover': {
        backgroundColor: 'aiHub.grey.600',
      },
    },
    cta: {
      textTransform: 'none',
      color: 'white',
      backgroundColor: 'aiHub.blue.600',
      borderRadius: '32px',
    },
  },
  light: {
    primary: {
      minHeight: '40px',
      boxShadow: 'none',
      backgroundColor: 'aiHub.blue.600',
      color: 'white',
      textTransform: 'none',
      borderRadius: '32px',
      '&.MuiButton-containedPrimary:hover': {
        boxShadow: 'none',
        backgroundColor: 'aiHub.blue.500',
      },
    },
    secondary: {
      minHeight: '40px',
      boxShadow: 'none',
      textTransform: 'none',
      borderRadius: '32px',
      color: 'rgb(0, 52, 155)',
      backgroundColor: 'aiHub.blue.300',
      '&.MuiButton-containedSecondary:hover': {
        boxShadow: 'none',
        backgroundColor: 'aiHub.blue.200',
      },
    },
    cta: {
      backgroundColor: 'aiHub.blue.600',
      color: 'white',
      textTransform: 'none',
      borderRadius: '32px',
      '&.MuiButton-containedPrimary:hover': {
        backgroundColor: 'aiHub.blue.500',
      },
    },
  },
};

function CustomButton(props: ButtonProps) {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const color = props.color ?? 'primary';
  const narrowedColor = ['primary', 'secondary', 'cta'].includes(color)
    ? (color as 'primary' | 'secondary' | 'cta')
    : 'primary';
  const withModeSx: ButtonProps['sx'] = sx[mode][narrowedColor];
  const withProvidedSx: ButtonProps['sx'] = props.sx ?? {};
  const withSx: ButtonProps['sx'] = { ...withModeSx, ...withProvidedSx };
  return (
    <Button variant="contained" {...props} sx={withSx}>
      {props.children}
    </Button>
  );
}

export default CustomButton;

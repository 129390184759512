import { useEffect } from 'react';

/**
 * This hooks detects the source param during the sign-in process and set's a
 * cookie called `ai-hub.login-source` with the value of the source param.  This
 * allows us to track the source of the login for analytics purposes.
 */
export function useSetTimezoneCookie() {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setCookie('ai-hub.login-timezone', timezone);
  }, []);
}

function setCookie(name: string, value: string) {
  document.cookie =
    name + '=' + (value || '') + '; path=/;Secure;SameSite=None';
}

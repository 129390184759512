import { deleteCookie } from '@chat-dit/shared-utils/client';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Button, Text } from '@chat-dit/shared-ui-design-system';
import { InfoOutlined } from '@mui/icons-material';

function DemoMode() {
  const [isDemoMode, setIsDemoMode] = useState(false);
  useEffect(() => {
    const cookies = document.cookie.split(';');
    const demoModeCookie = cookies.find((cookie) =>
      cookie.trim().startsWith('demo_mode=')
    );
    if (demoModeCookie) {
      setIsDemoMode(true);
    }
  }, []);
  if (!isDemoMode) {
    return null;
  }
  function handleExitDemoMode() {
    deleteCookie('demo_mode', '/', '');
    window.location.reload();
  }

  return (
    <Box display="flex" justifyContent="center" mb={4}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="aiHub.blue.300"
        justifyContent="space-between"
        p={2}
        borderRadius={4}
        sx={{ width: '400px' }}
        color="black"
      >
        <Box display="flex" alignItems="center">
          <InfoOutlined sx={{ mr: 1 }} />
          <Text fontWeight="bold">You are in demo mode</Text>
        </Box>
        <Button onClick={handleExitDemoMode}>Exit</Button>
      </Box>
    </Box>
  );
}

export default DemoMode;

import { Search } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  useTheme,
} from '@mui/material';

const sx = {
  dark: {
    borderRadius: '24px',
    backgroundColor: '#292929',
    borderColor: 'aiHub.grey.600',
    color: 'aiHub.grey.400',
  },
  light: {
    backgroundColor: 'white',
    borderRadius: '24px',
  },
};

function SearchInput({
  textFieldProps,
  iconColor,
  mobile,
}: {
  textFieldProps: TextFieldProps;
  iconColor: 'default' | 'agent' | 'task';
  mobile: boolean;
}) {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const isDarkMode = mode === 'dark';
  const withModeSx: TextFieldProps['sx'] = sx[mode];
  const withProvidedSx: TextFieldProps['sx'] = textFieldProps.sx ?? {};
  const withSx: TextFieldProps['sx'] = { ...withModeSx, ...withProvidedSx };

  function getIconBackgroundColor() {
    if (isDarkMode) return theme.palette.aiHub.grey[700];
    if (iconColor === 'task') {
      return theme.palette.task.background;
    }
    if (iconColor === 'agent') {
      return theme.palette.aiHub.purple[200];
    }
    return theme.palette.aiHub.blue[200];
  }

  function getIconColor() {
    if (iconColor === 'task') {
      return isDarkMode ? theme.palette.task.main : theme.palette.task.dark;
    }
    if (iconColor === 'agent') {
      return isDarkMode
        ? theme.palette.aiHub.purple[400]
        : theme.palette.aiHub.purple[600];
    }
    return theme.palette.secondary.main;
  }

  return (
    <TextField
      placeholder="Search"
      sx={{ ...withSx }}
      inputProps={{
        sx: {
          padding: 0,
          paddingX: mobile ? 0 : 1,
          color: isDarkMode ? 'aiHub.grey.400' : 'black',
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disableRipple
              sx={{
                backgroundColor: getIconBackgroundColor(),
                padding: '12px',
                margin: 0,
              }}
            >
              <Search htmlColor={getIconColor()} />
            </IconButton>
          </InputAdornment>
        ),
        sx: {
          borderRadius: '24px',
          padding: '12px',
          paddingRight: 0,
        },
      }}
      {...textFieldProps}
    ></TextField>
  );
}

export default SearchInput;

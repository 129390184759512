// this is the logger for the browser
import pino, { Logger, LoggerOptions } from 'pino';

function createLogger() {
  const config = {
    serverUrl:
      typeof window !== 'undefined'
        ? window.location.origin
        : process.env.NEXTAUTH_URL,
    env: process.env.ENV_NAME ?? process.env.NODE_ENV,
    publicUrl:
      typeof window !== 'undefined'
        ? window.location.origin
        : process.env.NEXTAUTH_URL,
  };

  const pinoConfig: LoggerOptions = {
    level: 'trace',
    browser: {
      asObject: true,
    },
    formatters: {
      level: (label) => {
        return { level: label };
      },
    },
  };

  if (config.serverUrl && pinoConfig.browser) {
    pinoConfig.browser.transmit = {
      level: 'info',
      send: (level, logEvent) => {
        const msg =
          typeof logEvent.messages[0] === 'string'
            ? logEvent.messages[0]
            : logEvent.messages[1];
        let data =
          typeof logEvent.messages[0] === 'object'
            ? logEvent.messages[0]
            : null;
        const { ts, bindings } = logEvent;
        const headers = {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers':
            'Origin, X-Requested-With, Content-Type, Accept',
          type: 'application/json',
        };

        let error = null;

        if (level === 'error') {
          if (data && 'stack' in data) {
            error = data;
            data = {};
          }

          if (data && 'err' in data) {
            if ('stack' in data.err && 'message' in data.err) {
              error = { message: data.err.message, stack: data.err.stack };
            } else if (typeof data.err === 'string') {
              error = { message: data.err };
            }
            data = { ...data };
            delete data.err;
          }
        }

        const blob = new Blob(
          [
            JSON.stringify({
              msg,
              level,
              ts,
              error,
              payload: {
                ...bindings.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
                ...data,
              },
            }),
          ],
          headers
        );
        navigator.sendBeacon(`${config.serverUrl}/api/log`, blob);
      },
    };
  }
  return pino({
    ...pinoConfig,
    customLevels: {
      metric: 100,
    },
  });
}

let logger: Logger | null = null;

export const loggerService = {
  getLogger() {
    if (!logger) {
      logger = createLogger();
    }
    return logger;
  },
};

import { Button } from '@mui/material';
import { getCsrfToken } from 'next-auth/react';
import { useEffect, useState } from 'react';

export function DevSignIn() {
  const [csrfToken, setCsrfToken] = useState<string | null>(null);

  useEffect(() => {
    getCsrfToken().then((token) => setCsrfToken(token ?? null));
  }, []);

  if (typeof window === 'undefined') {
    return null;
  }

  if (!csrfToken) {
    return null;
  }

  return (
    <form
      method="post"
      action="/api/auth/callback/credentials"
      style={{ textAlign: 'center', marginTop: 16 }}
    >
      <input name="signInType" type="hidden" defaultValue={'dev'} />
      <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
      <Button
        variant="contained"
        color="error"
        type="submit"
        size="large"
        sx={{
          borderRadius: '4rem',
          textTransform: 'none',
          fontWeight: 'normal',
          fontSize: '0.9rem',
          color: 'white',
        }}
      >
        Sign in as Developer
      </Button>
    </form>
  );
}

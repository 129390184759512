import { Theme, useTheme } from '@mui/material';

function ArticleShortcutIcon({
  color,
}: {
  color: string | ((theme: Theme) => string);
}) {
  const theme = useTheme();
  const fillColor = typeof color === 'function' ? color(theme) : color;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3788_35203"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill={fillColor} />
      </mask>
      <g mask="url(#mask0_3788_35203)">
        <path
          d="M13.3333 22.6667H18.6667V20H13.3333V22.6667ZM13.3333 17.3333H22.6667V14.6667H13.3333V17.3333ZM9.33333 12H22.6667V9.33333H9.33333V12ZM8.83333 29.3333C7.07778 29.3333 5.58333 28.7167 4.35 27.4833C3.11667 26.25 2.5 24.7556 2.5 23C2.5 21.7333 2.82778 20.6 3.48333 19.6C4.13889 18.6 5 17.8444 6.06667 17.3333H2.66667V14.6667H10.6667V22.6667H8V19.4333C7.17778 19.6111 6.5 20.0333 5.96667 20.7C5.43333 21.3667 5.16667 22.1333 5.16667 23C5.16667 24.0222 5.52778 24.8889 6.25 25.6C6.97222 26.3111 7.83333 26.6667 8.83333 26.6667V29.3333ZM13.3333 28V25.3333H25.3333V6.66667H6.66667V12H4V6.66667C4 5.93333 4.26111 5.30556 4.78333 4.78333C5.30556 4.26111 5.93333 4 6.66667 4H25.3333C26.0667 4 26.6944 4.26111 27.2167 4.78333C27.7389 5.30556 28 5.93333 28 6.66667V25.3333C28 26.0667 27.7389 26.6944 27.2167 27.2167C26.6944 27.7389 26.0667 28 25.3333 28H13.3333Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}

export default ArticleShortcutIcon;

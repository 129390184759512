import {
  applyFont,
  copernicus,
  copernicusBold,
  notoSans,
  notoSansBold,
  polaris,
  polarisBold,
} from '@chat-dit/ui-util-fonts';
import { Typography, TypographyProps } from '@mui/material';
import { forwardRef } from 'react';

const fontMap = {
  copernicus,
  copernicusBold,
  notoSans,
  notoSansBold,
  polaris,
  polarisBold,
};

type TextProps = TypographyProps & {
  font?:
    | 'copernicus'
    | 'copernicusBold'
    | 'notoSans'
    | 'notoSansBold'
    | 'polaris'
    | 'polarisBold';
};

const Text = forwardRef<HTMLSpanElement, TextProps>(function Text(props, ref) {
  const withProvidedSx: TextProps['sx'] = props.sx ?? {};
  let withSx: TextProps['sx'];
  if (withProvidedSx instanceof Object) {
    withSx = {
      ...withProvidedSx,
      ...(props.font ? applyFont(fontMap[props.font]) : {}),
      ...(props.fontWeight ? { fontWeight: props.fontWeight } : {}),
    };
  }
  if (withProvidedSx instanceof Array) {
    withSx = [
      ...withProvidedSx,
      {
        ...(props.font ? applyFont(fontMap[props.font]) : {}),
        ...(props.fontWeight ? { fontWeight: props.fontWeight } : {}),
      },
    ];
  }
  return (
    <Typography {...props} sx={withSx} ref={ref}>
      {props.children}
    </Typography>
  );
});

export default Text;

import { AIModel } from '@chat-dit/shared/data-ai-hub-api';
import Image from 'next/image';
import { useMode } from '../../hooks';

function ModelIcon({
  model,
  height,
  width,
}: {
  model: AIModel;
  height: number;
  width: number;
}) {
  const isDarkMode = useMode() === 'dark';
  function getFilter() {
    if (!model.id.includes('gpt-4')) return 'none';
    if (isDarkMode) return 'none';
    return 'invert(1)';
  }
  return (
    <Image
      src={model.image}
      alt={`${model.name} logo`}
      height={height}
      width={width}
      quality={100}
      style={{
        filter: getFilter(),
      }}
    />
  );
}

export default ModelIcon;

/**
 * This hooks detects the source param during the sign-in process and set's a
 * cookie called `ai-hub.login-source` with the value of the source param.  This
 * allows us to track the source of the login for analytics purposes.
 */
export function useSetLoginSourceCookie() {
  return function (loginSource: string) {
    if (typeof window === 'undefined') {
      return;
    }
    setCookie('ai-hub.login-source', loginSource);
  };
}

function setCookie(name: string, value: string, minutes = 1) {
  let expires = '';
  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

import { getCsrfToken } from 'next-auth/react';
import { useEffect, useState } from 'react';

export function CypressSignIn() {
  const [csrfToken, setCsrfToken] = useState<string | null>(null);

  useEffect(() => {
    getCsrfToken().then((token) => setCsrfToken(token ?? null));
  }, []);

  if (typeof window === 'undefined') {
    return null;
  }

  if (!('Cypress' in window)) {
    return null;
  }

  if (!csrfToken) {
    return null;
  }

  return (
    <form method="post" action="/api/auth/callback/credentials">
      <input name="signInType" type="hidden" defaultValue={'cypress'} />
      <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
      <div>
        <label>
          Username
          <input name="username" type="text" />
        </label>
      </div>
      <div>
        <label>
          Password
          <input name="password" type="password" />
        </label>
      </div>
      <div>
        <label>
          Access Token
          <input name="access_token" type="password" />
        </label>
      </div>
      <button type="submit">Sign in</button>
    </form>
  );
}

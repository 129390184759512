import { Box, Button } from '@mui/material';
import { signIn } from 'next-auth/react';

export function NorthwellSignIn() {
  const getCallbackUrl = () => {
    if (typeof window === 'undefined') {
      return '/';
    }
    const url = new URL(window.location.href);
    const redirectPath = url.searchParams.get('redirect');

    // If we have search parameters make sure we include them in the callback URL.
    if (url.searchParams.size > 0) {
      const entries = Array.from(url.searchParams.entries());
      const query = entries
        .filter(([key]) => key !== 'redirect') // Exclude the "redirect" param from other params
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      if (redirectPath) {
        return `${redirectPath}${query ? `?${query}` : ''}`;
      }
      return `/?${query}`;
    }
    // Otherwise, just return the root URL
    return '/';
  };
  return (
    <Box textAlign="center">
      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={() => signIn('azure-ad', { callbackUrl: getCallbackUrl() })}
        sx={{
          borderRadius: '4rem',
          textTransform: 'none',
          fontWeight: 'normal',
          fontSize: '0.9rem',
          color: 'white',
        }}
      >
        Sign in with Northwell credentials
      </Button>
    </Box>
  );
}

'use client';

import { useEffect, useState } from 'react';
import { getCsrfToken } from 'next-auth/react';
import { Box, Typography } from '@mui/material';
import { useSearchParams } from 'next/navigation';

export function TokenSignIn() {
  const [csrfToken, setCsrfToken] = useState<string | null>(null);
  const searchParams = useSearchParams();
  const [form, setForm] = useState<HTMLFormElement | null>(null);

  useEffect(() => {
    getCsrfToken().then((token) => setCsrfToken(token ?? null));
  }, []);

  useEffect(() => {
    if (!form) {
      return;
    }
    form.submit();
  }, [form]);

  if (typeof window === 'undefined') {
    return null;
  }

  if (!csrfToken) {
    return null;
  }

  return (
    <>
      <form
        ref={(node) => setForm(node)}
        method="post"
        action="/api/auth/callback/credentials"
        id="teamsForm"
      >
        <input name="signInType" type="hidden" defaultValue={'token'} />
        <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
        <input
          name="access_token"
          type="hidden"
          value={searchParams.get('token') ?? ''}
          onChange={() => {
            // noop
          }}
        />
        <button type="submit" style={{ display: 'none' }}>
          Sign in
        </button>
      </form>
      <Box textAlign="center">
        <Typography>
          Automatically signing you in using the provided token...
        </Typography>
      </Box>
    </>
  );
}

import { Box, Theme, Tooltip, TooltipProps, useTheme } from '@mui/material';
import { FunctionComponent } from 'react';

const UserIcon: FunctionComponent<{
  username: string;
  color: string | ((theme: Theme) => string);
  sx?: TooltipProps['sx'];
}> = (props) => {
  const theme = useTheme();
  const color = props.color
    ? typeof props.color === 'string'
      ? props.color
      : (props.color(theme) as string)
    : undefined;
  return (
    <Box sx={{ ...(props.sx ?? {}), fontSize: 0 }}>
      <Tooltip title={props.username}>
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.41683 26.0834C8.05572 24.9815 9.73627 24.1366 11.4585 23.5487C13.1807 22.9607 15.0279 22.6667 17.0002 22.6667C18.9724 22.6667 20.8242 22.9607 22.5557 23.5487C24.2872 24.1366 25.9724 24.9815 27.6113 26.0834C28.7502 24.7037 29.5812 23.2639 30.1043 21.7639C30.6275 20.2639 30.8891 18.676 30.8891 17C30.8891 13.0834 29.5534 9.78939 26.8821 7.11808C24.2108 4.44678 20.9168 3.11112 17.0002 3.11112C13.0835 3.11112 9.78951 4.44678 7.11821 7.11808C4.4469 9.78939 3.11125 13.0834 3.11125 17C3.11125 18.676 3.37745 20.2639 3.90987 21.7639C4.44229 23.2639 5.27794 24.7037 6.41683 26.0834ZM16.995 18.3889C15.3781 18.3889 14.0164 17.834 12.9099 16.724C11.8034 15.6141 11.2502 14.2507 11.2502 12.6337C11.2502 11.0168 11.8051 9.65514 12.915 8.54867C14.025 7.44217 15.3884 6.88892 17.0053 6.88892C18.6222 6.88892 19.984 7.44389 21.0905 8.55383C22.1969 9.66375 22.7502 11.0272 22.7502 12.6441C22.7502 14.261 22.1952 15.6227 21.0853 16.7292C19.9753 17.8357 18.6119 18.3889 16.995 18.3889ZM16.9889 33.6667C14.6807 33.6667 12.5115 33.2292 10.4813 32.3542C8.45112 31.4792 6.68515 30.2871 5.18337 28.7778C3.68159 27.2686 2.49784 25.5015 1.63212 23.4767C0.766371 21.4519 0.333496 19.2884 0.333496 16.9862C0.333496 14.6839 0.770996 12.5204 1.646 10.4956C2.521 8.47079 3.71312 6.70837 5.22237 5.20837C6.73165 3.70837 8.49869 2.52087 10.5235 1.64587C12.5483 0.770874 14.7118 0.333374 17.014 0.333374C19.3163 0.333374 21.4798 0.770874 23.5046 1.64587C25.5294 2.52087 27.2918 3.70837 28.7918 5.20837C30.2918 6.70837 31.4793 8.47226 32.3543 10.5C33.2293 12.5278 33.6668 14.692 33.6668 16.9927C33.6668 19.2933 33.2293 21.4553 32.3543 23.4788C31.4793 25.5022 30.2918 27.2686 28.7918 28.7778C27.2918 30.2871 25.5267 31.4792 23.4966 32.3542C21.4664 33.2292 19.2972 33.6667 16.9889 33.6667Z"
            fill={color}
          />
        </svg>
      </Tooltip>
    </Box>
  );
};

export default UserIcon;

import { Switch as MuiSwitch, SwitchProps, useTheme } from '@mui/material';

function Switch(props: SwitchProps) {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const withProvidedSx: SwitchProps['sx'] = props.sx ?? {};
  return (
    <MuiSwitch
      {...props}
      sx={{
        width: 42,
        height: 22,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          transitionDuration: '300ms',
          height: '100%',
          width: '70%',
          '&:hover': {
            backgroundColor: 'unset',
          },
          '&.Mui-checked': {
            transform: 'translateX(14px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&:not(.Mui-checked)': {
            '& .MuiSwitch-thumb': {
              // Styles for unchecked thumb
              backgroundColor: 'aiHub.grey.500',
            },
          },
          '& .MuiSwitch-thumb': {
            height: 14,
            width: 14,
            color: mode === 'dark' ? 'black' : 'white',
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: 'theme.palette.red[600]',
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: 'rgba(0,0,0,0)',
          border: '2px solid',
          borderColor: 'aiHub.grey.500',
          opacity: 1,
          transition: 'background-color 0ms, border-color 0ms',
        },
        ...withProvidedSx,
      }}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
    />
  );
}

export default Switch;
